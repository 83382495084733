body {
  color: $black;
}

// font size
.font-size-xs {
  font-size: $font-size-xs !important;
}

.font-size-sm {
  font-size: $font-size-sm !important;
}

.font-size-base {
  font-size: $font-size-base !important;
}

//fonts
.font-family-montserrat {
  font-family: $montserrat !important;
}

// font-weight
.font-weight-semi-bold {
  font-weight: $font-weight-semi-bold !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal !important;
}

.font-weight-medium {
  font-weight: $font-weight-medium !important;
}

// text color
.text-red-10 {
  color: $red-10 !important;
}

.heading1 {
  font-size: 2rem;
}

.heading3 {
  font-size: 1.25rem;
}

.heading4 {
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
}

.heading4-bold {
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  letter-spacing: 0.0625rem;
}

.font-size-13 {
  font-size: 0.8125rem;
}

.main-content {
  padding-top: 6.25rem;
  padding-left: 342px;
}

.search-wrap {
  padding-left: 246px;
}

.text-underline {
  text-decoration: underline;
}

.content-sm {
  width: 40.75rem;
}

.content-md {
  width: 60.75rem;
}

.text-secondary {
  color: rgba($black, 0.6) !important;
}

.page-bottom-space {
  padding-bottom: 1.5rem;
}

.bg-gray {
  background-color: rgba($black, 0.05) !important;
}

.mw-100 {
  min-width: 100% !important;
}

.detail-list {
  li {
    &:nth-child(2n + 1) {
      background-color: rgba($black, 0.05);
    }
  }
}

.pointer-none {
  pointer-events: none !important;
}

.has-right-action {
  position: relative;
  padding-right: 3rem;

  .action-wrap {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.hasClose {
  position: relative;
  padding-right: 18px !important;

  .close-btn {
    font-size: 12px;
    line-height: 1;
    position: absolute;
    z-index: 1;
    top: 3px;
    right: 2px;
  }
}

.logos-template-wrap {
  padding: 12px 24px;
  border: 1px solid rgba($black, 0.12);
  border-radius: 8px;
  margin-bottom: 24px;
}

.multilevel-dropdown-wrapper {
  max-height: 300px;
  overflow: auto;
}

// .multiple-level-select {
//     padding: 0;

//     .toggle-btn {
//         width: 100%;
//         text-align: left;
//         justify-content: flex-start;
//         background-color: $transparent !important;
//         height: 38px;
//         font-size: 16px;
//         font-weight: 400;
//         padding: 0.375rem 2.25rem 0.375rem 0.75rem;
//     }

//     .drop-menu {
//         width: 100%;
//         padding: 0;
//         max-height: 300px;
//         overflow-y: auto;
//         li {
//             &:hover {
//                 color: $red-10;
//                 background-color: rgba($red-10, 0.1);
//             }
//         }
//     }
//     .drop-submenu {

//     }
// }

.multiple-level-select {
  padding: 0;
  width: 100%;
  margin-bottom: 15px;

  .drop-menu {
    li {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .toggle-btn {
    width: 100%;
    text-align: left;
    justify-content: flex-start;
    background-color: $transparent !important;
    height: 38px;
    font-size: 16px;
    font-weight: 400;
    padding: 0.375rem 2.25rem 0.375rem 0.75rem;
    border: 1px solid rgba($black, 0.5);

    &.Dropdown_active__MizvA {
      &::before {
        transform: rotate(-135deg);
      }
    }
  }

  .template-group-arrow {
    &::before {
      content: "";
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      border-top: 6px solid transparent;
      border-left: 12px solid #d11a1a;
      border-bottom: 6px solid transparent;
    }
  }

  .template-btn-arrow {
    &::before {
      content: "";
      position: absolute;
      right: 18px;
      top: 12px;
      width: 10px;
      height: 10px;
      border-bottom: 2px solid;
      border-right: 2px solid;
      transform: rotate(45deg);
    }
  }

  .drop-menu {
    width: 100%;
    padding: 0;

    li {
      &:hover {
        color: $red-10;
        background-color: rgba($red-10, 0.1);
      }
    }
  }

  .template-list-menu {
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
  }
}

.default-logos {
}

.custom-new-table {
  th {
    text-align: center;
    vertical-align: middle;
  }

  thead {
    th {
      background-color: rgba($black, 0.05);
    }
  }

  .form-control {
    background-color: transparent;
    border: none;
    text-align: center;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }

  td,
  th {
    border: 1px solid rgba($black, 0.12);
    min-width: 150px;
    vertical-align: middle;
  }

  .custom-checkbox {
    width: 20px;
    height: 20px;
    margin: 0 auto;
  }
}

.has-heading-sticky {
  position: relative;

  &::before {
    position: fixed;
    margin-top: -19px;
    content: "";
    height: 19px;
    width: 100%;
    background-color: #fff;
    z-index: 999;
  }

  .heading-wrap {
    position: sticky;
    top: 100px;
    z-index: 999;
    background-color: #fff;
  }
}

.bordered-table {
  th,
  td {
    padding: 16px;
    border: 1px solid rgba($black, 0.12);

    p {
      color: $black;

      &:last-child {
        margin-bottom: 0;
      }
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
}

.four-spacing {
  > div {
    &:nth-child(odd) {
      .row {
        margin-right: 12px;
      }
    }

    &:nth-child(even) {
      .row {
        margin-left: 12px;
      }
    }
  }
}

.space10 {
  // display: flex;
  // justify-content: space-between;
  padding: 0 10px;
}

.tooltip {
  z-index: 998 !important;
}

.logo-remove-btn {
  position: absolute;
  top: -30%;
  right: -30%;
  border: none;
  background: none;
}

.align-items-center {
  align-items: center;
}

.icon-btn {
  background: none;
  border: none;
}

.nestedModal-l1 {
  z-index: 1056;
}

/********** GRAPHIC COLOR STYLE **********/
.graphic-color-box {
  padding-top: 10px;
  padding-right: 25px;
  padding: 10px 25px 50px 0;

  .graphic-color-bottom {
    max-height: 600px;
    overflow-y: auto;
    padding-right: 10px; // adds space for the scrollbar

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: #cdcbcb;
      width: 10px;
      height: 5px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #c21b17;
    }
  }

  .graphic-color-top {
    margin-bottom: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ece8e8;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .color-group-top {
    position: relative;
  }

  .graphic-color-heads {
    color: $black;
    font-weight: 700;
  }

  .graphic-list-wrap {
    list-style-type: none;
    padding-left: 0;
    margin: 0 -20px;
    display: flex;

    li {
      padding: 0 20px;
    }

    .color-group-btn {
      border: none;
      background: none;
      font-size: 18px;
      line-height: 22px;

      .icon {
        margin-right: 6px;
        color: $red-11;
      }
    }
  }

  .color-group-list {
    min-height: 300px;
    display: flex;
    padding: 0;
    list-style-type: none;
    // margin: 0 -17px;

    li {
      width: 25%;
      min-width: 350px;
      padding: 0 17px;
    }
  }

  .color-group-top {
    position: relative;
    padding-right: 24px;

    .edit-name-btn {
      padding: 0;
      border: none;
      background: none;
      color: #979191;
      font-size: 14px;
      line-height: 14px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .text-with-icon {
    font-size: 18px;
    line-height: 24px;
    color: $black;
    position: relative;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .color-name-list {
    list-style-type: none;
    padding-left: 0;

    .color-name-item {
      padding: 15px 0;
      border-top: 1px solid #ece8e8;
      // cursor: pointer;

      &:first-child {
        padding-top: 0;
        border-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }

    .color-name-wrap {
      padding-left: 35px;
      padding-right: 30px;
      position: relative;

      .btn {
        background: none;
        border: none;
        padding: 0;
        position: absolute;
        color: #979191;
        width: 18px;
        height: 18px;
        top: 50%;
        transform: translateY(-50%);

        span {
          font-size: 18px;
          line-height: 18px;
        }

        &.dragable {
          left: 0;
        }

        &.edit-color {
          right: 0;
        }
      }
    }

    .color-name-text {
      color: $black;
      font-weight: 400;
      font-size: 18px;
      line-height: 20px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.icon-dropdown {
  position: absolute;
  right: 0;
  top: 14px;
  padding: 0;
  height: auto;
  border-radius: unset;

  .dropdown-container {
    margin-left: 4px;
    margin-right: 4px;
  }

  .dropdown-toggle {
    &:focus {
      box-shadow: none;
    }

    &::after {
      content: none;
    }
  }

  .dropdown-menu {
    background-color: #e8e9ea;
    border: none;
    border-radius: 6px;
    padding: 12px 0;
  }

  .dropdown-item {
    font-family: $helvetica;
    font-size: 14px;
    line-height: 17px;
    color: $black;
    font-weight: 600;
    border-radius: 4px;
    padding: 3px 15px;
    margin-top: 2px;
    text-transform: capitalize;

    &:first-child {
      margin-top: 0;
    }

    &:hover {
      color: $white;
      background-color: #7c9acf;
    }
  }
}

.dropdown-select {
  margin-bottom: 16px;
  padding: 0;
  width: 100%;
  .dropdown-toggle {
    background-color: $white;
    color: #212529;
    border-color: #ced4da;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
  .dropdown-menu {
    padding: 0;
    width: 100%;
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
  }
}

/********** GRAPHIC COLOR STYLE **********/
